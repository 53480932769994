@tailwind base;
@tailwind components;
@tailwind utilities;

/* -------------------
TYPOGRAPHY
---------------------- */

:root {
    --sans-serif-type: "Inter Tight", sans-serif;
    --serif-type: "Newsreader", serif;
    --caps-letter-spacing: 0.225ch;
    --sans-head-letter-spacing: 0.02ch;
}

html {
    font-family: var(--sans-serif-type);
    height: 100%;
    margin: 0;
}

body {
    height: 100%;
    margin: 0;
}

#root {
    height: 100%;
}

#page-container{
    position: relative;
    min-height: 100vh;
}

#footer{
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 2.5rem;
}

h1,
h2 {
    font-family: var(--serif-type);
}

h1 {
    letter-spacing: -0.015em;
    font-weight: 440;
}

h2 {
    font-weight: 500;
}

h3,
h4,
h5,
h6 {
    font-family: var(--sans-serif-type);
    font-weight: 400;
}

h3 {
    letter-spacing: var(--sans-head-letter-spacing);
}

h4 {
    font-weight: 500;
    letter-spacing: var(--sans-head-letter-spacing);
}

h5 {
    font-weight: 600;
    letter-spacing: var(--sans-head-letter-spacing);
}

h6 {
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: var(--caps-letter-spacing);
}

p {
    font-family: var(--serif-type);
    font-weight: 430;
    border: 1px darkred;
}

dt {
    font-weight: 600;
    font-family: var(--serif-type);
    text-transform: uppercase;
    letter-spacing: var(--caps-letter-spacing);
}

dd {
    letter-spacing: var(--sans-head-letter-spacing);
}

figcaption {
    font-family: var(--serif-type);
}

sub,
sup {
    font-family: var(--sans-serif-type);
}

.App{
    height:100%;
}

.wrapper {
    width: 100%;
    height: 100px;
    background: black;
    margin-top: 10px;
}

.scrollable-div {
  width: 400px;
  overflow-y: scroll;
  text-align: justify;
}

.signinBack{
    background-image: url("https://afcuploadstorageprod.blob.core.windows.net/assets/1/Admin_Login.png");
    height: 100%;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.signinVideoBack{
    height: 2000px;
    width: 2000px;
}


.signInCardBody{
    border-width: 25px;
    border-color: red;
}

.signinBody{
    height: calc(100vh - 300px);
    width: calc(50vw);
    margin: auto;
    width: 384px;
    z-index: 9;
}

.signinFooterTagLineRight{
    margin-top: 50px;
    margin-left: calc(100vw - 650px)
}

.signinFooter{
    z-index: 0;
    padding-left: 25px;
    width: 100vw;

}

.signinFooterMobile{
    z-index: 0;
    padding-left: calc(100vw - 220px);
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100vw;
    justify-content: center;
    height: "230px";
}

.accountHeader{
    width: 100vw;
    height: 150px;
    background: black;
    background-image: url("https://afcuploadstorageprod.blob.core.windows.net/assets/1/admin_banner.png");
    margin-top: 0px;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.accountHeaderMobile{
    width: 100vw;
    height: 250px;
    background: black;
    background-image: url("https://afcuploadstorageprod.blob.core.windows.net/assets/1/AdobeStock_589889496.jpeg");
    margin-top: 0px;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.accountDashboardLoading{
    margin-left: calc(50vw - 48px);
    margin-top: -60px;
}

.adminHeaderImage{
    height: 120px;
    width: 120px;
    margin: 0px 20px 0px 0px;
    src: url("https://afcuploadstorageprod.blob.core.windows.net/assets/1/Flame_Icon_ShapeFile_BW.png");
}

.userEditLogo{
    background-image: url("https://afcuploadstorageprod.blob.core.windows.net/assets/1/hexagonBackground.png");
    margin: 10px 10px 10px 10px;
}

.userEditLogoMask{
    background: black;
    opacity: 60%;
    height: 100%;
}

.userEditTile{
    background: white;
    margin: 10px 10px 10px 0px;
    vertical-align: middle;
}

.adminHeaderName{
    padding-top: 17px;
    margin-left: 50px;
}

.adminHeaderNameMobile{
    margin-left: 20px;
    margin-bottom: 10px;
}

.adminHeaderTitle{
    font-weight: bold;
    font-size: 72px;
    color: white;
}

.adminHeaderDescription{
    font-weight: bold;
    font-size: 28px;
    color: white;
    margin-left: 190px;
    margin-top: -35px;
    opacity: 75%;
}

.dataMapperFile{
    height: 100px;
    width: 350px;
    border-radius: 20px;
    border-color: gray;
    border-width: 1px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.datamapperFileName{
    width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.DataOverflowScroll{
    overflow: scroll;
}

.eventCreateDetailsBehind{
    position: relative;
    background: lightgrey;
    height: 1095px;
    width: 20vw;
    border-radius: 20px;
    margin-top: 0px;
    padding: 10px 10px 10px 20px;
}

.eventCreateDetailsBehind.fixed{
    position: fixed;
    background: lightgrey;
    height: 1000px;
    width: 20vw;
    border-radius: 20px;
    top: 6%;
    margin-top: 3rem;
    padding: 10px 10px 10px 20px;
}

.eventCreateFunctionTile{
    background: lightgrey;
    height: 300px;
    width: 454px;
    border-radius: 20px;
    padding: 10px 10px 10px 20px;
    margin-right: 10px;
    background: royalblue;
}

.eventCreateFunctionTileTitle{
    font-family: var(--sans-serif-type);
    color: black;
    font-weight: bold;
    font-size: 18px;
}

.eventCreateFunctionTileCount{
    font-family: var(--sans-serif-type);
    color: white;
    font-weight: bold;
    font-size: 96px;
}

.eventCreateFunctionTileInformation{
    font-family: var(--sans-serif-type);
    color: black;
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
    height: 20px;
    text-decoration: underline;
    color: blue;
}

.eventCreateDetailForm{
    
}

.eventCreateHeaderInformation{
    border: 2px solid lightgrey;
    padding: 20px 20px 20px 20px;
    margin: 0px 20px 10px 0px;
    border-radius: 20px;
}

.eventCreateTiming{
    margin: 0px 20px 10px 0px;
    z-index: -1;
}

.eventEditCalloutTitle{
    font-family: var(--sans-serif-type);
    color: black;
    font-weight: bold;
    font-size: 32px;
}

.eventEditCalloutSectionTitle{
    font-family: var(--sans-serif-type);
    color: black;
    font-weight: bold;
    font-size: 24px;
}

.eventEditCalloutInformation{
    font-family: var(--sans-serif-type);
    color: black;
    font-size: 18px;   
}

.eventEditCalloutHR{
    background-color: black; 
    border-color: black;
    height: 1px;
    margin: 30px 20px 30px 20px;
}

.eventEdit_SMSBody{
    font-family: var(--sans-serif-type);
    font-size: 14px;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    background: lightblue;
    color: black;
}

.eventEdit_MMSBody{
    font-family: var(--sans-serif-type);
    font-size: 14px;
    margin-top: 15px;
    margin-bottom: 0px;
    margin-left: 20px;
    padding-bottom: 0px;
}

.eventEditApproverBounds{
    border-radius: 10px;
    border-color: gray;
    border-width: 1px;
    padding: 10px 10px 10px 10px;
    min-height: 165px;
    width: 480px;
}

.eventEditApprovers{
    border-width: 1px;
    border-radius: 10px;
    border-color: black;
    font-size: 12px;
    font-family: sans-serif;
    width: 460px;
    height: 30px;
    padding-top: 4px;
    text-align: center;
    margin-right: 30px;    
    background-color: #70ff77;
}

.eventEditApproversTarget{
    border-width: 1px;
    border-radius: 10px;
    border-color: black;
    font-size: 12px;
    font-family: sans-serif;
    width: 835px;
    min-height: 280px;
    padding-top: 4px;
    text-align: center;
    margin-right: 10px;
    background-color: white;
    justify-content: center;
    align-items: center;
}

.appliedApproverTargetRectangle{
    height: 230px;
    border-width: 2px;
    border-style: dashed;
    border-color: lightblue;
    fill: blue;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    margin: 0px 20px 0px 20px;
}

.appliedApprover{
    border-width: 1px;
    border-radius: 5px;
    border-color: black;
    font-size: 12px;
    font-family: sans-serif;
    min-height: 35px;
    margin-top: 4px;
    text-align: center;
    margin-left: 10px;
    margin-right: 10px;
    background-color: white;
    justify-content: left;
    align-items: center;
}

.eventEditSampleNB{
    border-width: 1px;
    border-radius: 10px;
    border-color: black;
    font-size: 12px;
    font-family: sans-serif;
    width: 460px;
    height: 30px;
    padding-top: 4px;
    text-align: center;
    margin-right: 30px;    
    background-color: #74dbff;
}

.eventEditSamplesTarget{
    border-width: 1px;
    border-radius: 10px;
    border-color: black;
    font-size: 12px;
    font-family: sans-serif;
    width: 835px;
    min-height: 280px;
    padding-top: 4px;
    text-align: center;
    margin-right: 10px;
    background-color: white;
    justify-content: center;
    align-items: center;
}

.appliedSampleTargetRectangle{
    height: 230px;
    border-width: 2px;
    border-style: dashed;
    border-color: lightblue;
    fill: blue;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    margin: 0px 20px 0px 20px;
}

.appliedSampleName{
    font-weight: bold;
    font-size: 12pt;
    font-family: sans-serif;
    width: 180px;
    text-align: left;
}

.approverButtons{
    cursor: pointer;
}

.appliedSample{
    border-width: 1px;
    border-radius: 5px;
    border-color: black;
    font-size: 12px;
    font-family: sans-serif;
    min-height: 35px;
    margin-top: 4px;
    text-align: center;
    margin-left: 10px;
    margin-right: 10px;
    background-color: white;
    justify-content: left;
    align-items: center;
}

.appliedApproverName{
    font-weight: bold;
    font-size: 12pt;
    font-family: sans-serif;
    width: 180px;
    text-align: left;
}

.adminHeaderTitle2{
    font-size: 72px;
    color: white;
    opacity: 75%;
}

.userEditBody{
    font-family: var(--sans-serif-type);
    color: black;
    font-size: 24px;
    font-weight: bold;
}

.userEditDesc{
    font-family: var(--sans-serif-type);
    color: black;
    font-size: 10px;
}

.userEditTitle{
    font-family: var(--sans-serif-type);
    color: black;
    font-size: 8px;
}


.container {
    display: flex;
    justify-content: center;
}

.flex-row{
    display: flex;
}

.floating-input > input::placeholder,
.floating-input > textarea::placeholder {
    color: transparent;
}
/* .floating-input>input:focus,
.floating-input>input:not(:placeholder-shown) {
    @apply pt-4
} */
.floating-input > input:focus ~ label,
.floating-input > input:not(:placeholder-shown) ~ label {
    @apply opacity-75 scale-75 -translate-y-4 translate-x-1;
}

.floating-input > textarea:focus ~ label,
.floating-input > textarea:not(:placeholder-shown) ~ label {
    @apply opacity-75 scale-75 -translate-y-6 translate-x-1;
}

.floating-input > select:focus ~ label,
.floating-input > select:valid ~ label {
    @apply opacity-75 scale-75 -translate-y-4 translate-x-1;
}

.login-title{
    font-family: Verdana, Arial, sans-serif;
    font-size: 30px;
}

.overlay{
    background-color: rgba(0,0,0,0.5);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
}

.modalContainer{
    width: 95vw;
    height: 95vh;
    position: fixed;
    display: flex;
    margin-left: 2.5vw;
    margin-top: 2.5vh;
    background-color: lightgrey;
    box-shadow: 0px 0px 18px 0px rgba(0,0,0,0.75);
}

.stepEllipse{
    height: 40px;
    width: 40px;
    border-radius: 50%;
    border-width: 2px;
    border-color: black;
}

.dropFileRectangle{
    display: flex;
    height: 150px;
    border-width: 2px;
    border-style: dashed;
    border-color: lightblue;
    fill: blue;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
}

.dropFileTitle{
    
    color: lightblue;
    font-family: sans-serif;
    font-weight: bold;
    font-size: 28px;
    cursor: pointer;
}

.draggableItemOptOut{
    border-width: 1px;
    border-radius: 10px;
    border-color: black;
    font-size: 12px;
    font-family: sans-serif;
    width: 250px;
    height: 30px;
    padding-top: 4px;
    text-align: center;
    margin-right: 30px;
    background-color: #e6effc;
}

.draggableItemMessage{
    border-width: 1px;
    border-radius: 10px;
    border-color: black;
    font-size: 12px;
    font-family: sans-serif;
    width: 250px;
    height: 30px;
    padding-top: 4px;
    text-align: center;
    margin-right: 30px;
    background-color: #ede6fc;
}

.draggableItemMember{
    border-width: 1px;
    border-radius: 10px;
    border-color: black;
    font-size: 12px;
    font-family: sans-serif;
    width: 250px;
    height: 30px;
    padding-top: 4px;
    text-align: center;
    margin-right: 30px;
    background-color: #fcf3e6;
}

@layer utilities {
    .ta-scrollbar::-webkit-scrollbar {
        width: 10px;
        height: 20px;
    }

    .ta-scrollbar::-webkit-scrollbar-track {
        border-radius: 100vh;
        background: #b3defd;
    }

    .ta-scrollbar::-webkit-scrollbar-thumb {
        background: #0d3958;
        border-radius: 100vh;
        border: 2px solid #a4d3f5;
    }

    .ta-scrollbar::-webkit-scrollbar-thumb:hover {
        background: #0d3958;
    }
}
